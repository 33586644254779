<template>
<div>

    <!-- banner start -->
    <section
      class="banner_sec self_banner_sec"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="self_banner_hed">
              <!-- <h1 class="heading_1 mb_20 text_white font_bold">Ergo-Ally</h1> -->
              <h3 class="heading_3 mb_20 text_white font_bold">Ergo-Ally</h3>
              <p class="mb_36 text_white font_size_30 font_bold">
                Your powerful, global and customizable self-assessment, training and ergo program management tool.
              </p>
              <a href="learn-more" class="btn btn_primary">Request a Demo</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- banner end -->

    <!-- Heres start -->
    <section class="heres_sec bg_grey padding_100 position-relative">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-xl-2">
            <div class="meet_logo">
              <img src="../../assets/home/images/self_assessments/meet_logo.png" alt="img" />
            </div>
          </div>
          <div class="col-md-12 col-xl-12">
            <h3 class="heading_3 font_bold text_black mb_60 text-center">
              Meet Your Ergo-Allies!
            </h3>
          </div>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col">
            <div class="heres_box">
              <div class="ergo-ally-img-section">
                <img src="../../assets/home/images/self_assessments/Self.svg" alt="icon" />
              </div>
              <h6 class="heading_6">Self-Assessment <br />& Training</h6>
            </div>
          </div>

          <div class="col">
            <div class="heres_box">
              <div class="ergo-ally-img-section">
                <img src="../../assets/home/images/self_assessments/Backend.svg" alt="icon" />
              </div>
              <h6 class="heading_6">Backend Data <br />& Metrics</h6>
            </div>
          </div>

          <div class="col">
              <div class="heres_box">
                <div class="ergo-ally-img-section">
                  <img src="../../assets/home/images/self_assessments/Scheduling.svg" alt="icon" />
                </div>
                <h6 class="heading_6">Scheduling & <br />Admin Tools</h6>
              </div>
          </div>

          <div class="col">
            <div class="heres_box">
              <div class="ergo-ally-img-section">
                <img src="../../assets/home/images/self_assessments/Scalable.svg" alt="icon" />
              </div>
              <h6 class="heading_6">Scalable <br />Solutions</h6>
            </div>
          </div>

          <div class="col">
            <div class="heres_box">
              <div class="ergo-ally-img-section">
                <img src="../../assets/home/images/self_assessments/Custom.svg" alt="icon" />
              </div>
              <h6 class="heading_6">Custom Comms <br />Packages</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Heres end -->

    <!-- counter start -->
    <section
      id="counter-stats"
      class="wow fadeInRight py_70 bg_primary"
      data-wow-duration="1.4s"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12 col-lg-12 col-xl-9">
            <div class="row">
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 stats">
                <div class="media">
                  <div class="media-body countries">
                    <div class="counting" data-count="21K">21</div>
                    <h5>Countries</h5>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 stats">
                <div class="media">
                  <div class="media-body users">
                    <div class="counting" data-count="100">100k+</div>
                    <h5>Users</h5>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 stats">
                <div class="media">
                  <div class="media-body">
                    <div class="counting" data-count="100">100+</div>
                    <h5>Engaging Videos</h5>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3 col-lg-3 col-xl-3 stats">
                <div class="media">
                  <div class="media-body">
                    <div class="counting" data-count="20">20+</div>
                    <h5>Companies</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-lg-12 col-xl-3">
            <div class="request_btn">
            <router-link class="" :to="{ name: 'learn_more' }">
                <h2>Request</h2>
                <p>A Demo Today!</p>
            </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- counter end -->
         <!-- meet start -->
    <section class="meet_sec padding_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-xl-3">
            <div class="video-library">
              <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              class="text_black"
              >
              <div class="video_box">
                <img
                  src="../../assets/home/images/self_assessments/meet-your-ergo-ally.png"
                  alt="img"
                  class="free_img"
                />
                <span>
                  <img
                    src="../../assets/home/images/free_resources/video.svg"
                    alt="img"
                    class="v_icon"
                  />
                  Meet Your Ergo-Ally
                </span>
              </div>
            </a>
            </div>
            <div class="video-library">
              <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
              class="text_black"
              >
              <div class="video_box">
                <img
                  src="../../assets/home/images/self_assessments/meet-your-dashboards.png"
                  alt="img"
                  class="free_img"
                />
                <span>
                  <img
                    src="../../assets/home/images/free_resources/video.svg"
                    alt="img"
                    class="v_icon"
                  />
                  Meet Your Dashboards
                </span>
              </div>
            </a>
            </div>
            <div class="video-library">
              <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal2"
              class="text_black"
              >
              <div class="video_box">
                <img
                  src="../../assets/home/images/self_assessments/meet-your-evaluation-tools.png"
                  alt="img"
                  class="free_img"
                />
                <span>
                  <img
                    src="../../assets/home/images/free_resources/video.svg"
                    alt="img"
                    class="v_icon"
                  />
                  Meet Your Evaluation Tools
                </span>
              </div>
            </a>
            </div>
          </div>
          <div class="col-md-12 col-xl-9 left_content">
            <div class="meet_content">
              <h3 class="heading_3">See What Ergo-Ally Has to Offer!</h3>
              <div class="row justify-content-center mt-3 seewhat">
                <div class="col-md-12 col-xl-4">
                  <div class="seewhat_box">
                    <h6 class="heading_6">Videos & Training</h6>
                    <div class="img-section">
                      <img src="../../assets/home/images/self_assessments/videos_trainings.png" alt="icon" />
                    </div>                   
                  </div>
                </div>
                <div class="col-md-12 col-xl-4">
                  <div class="seewhat_box">
                    <h6 class="heading_6">Self-Assessments</h6>
                    <div class="img-section">
                      <img src="../../assets/home/images/self_assessments/self-assessments.png" alt="icon" />
                    </div>                   
                  </div>
                </div>
                <div class="col-md-12 col-xl-4">
                  <div class="seewhat_box">
                    <h6 class="heading_6">Dashboards & Metrics</h6>
                    <div class="img-section">
                      <img src="../../assets/home/images/self_assessments/dashboards_metrics.png" alt="icon" />
                    </div>                   
                  </div>
                </div>
              </div>
              <h3 class="heading_3">How Can Ergo-Ally Help You and Your Organization?</h3>
              <p>
                Managing an ergo program with a dispersed workforce is no easy feat. Ergo-Ally gives you oversight of your entire program and all ergo activity and insight and analysis of trends, hot spots and cost saving measures. Through your dynamic dashboard, you have 24/7 access to a powerful database containing employee records, metrics and much more! Whether employees are working from home, the office or hybrid setting, Ergo-Ally has impactful resources for everyone.
              </p>

              <h3 class="heading_3">
                How Can Ergo-Ally Help Your Employees?
              </h3>
              <p>
                Ergo-Ally will arm you and your employees with the training and strategies you need to successfully manage your organization’s ergo needs. Whether you are expanding locations, dealing with return-to-work or work-from-home, have multiple campuses or a global presence, Ergo-Ally is your “all-in-one” ergo management tool. Your employees will have 24/7 global access to self-assessments, ergo set-up guidelines, productivity tips, wellness strategies, stretches, posture perfect exercises, video playlists and more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- meet end -->
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed"
                src="https://player.vimeo.com/video/1008843882?h=f467c47d48&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Sitting Pretty"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal1"
      tabindex="-1"
      aria-labelledby="exampleModalLabel1"
      aria-hidden="true"
      >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal1"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed1"
                src="https://player.vimeo.com/video/1008843765?h=406fd02e34&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Sitting Pretty"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      class="modal fade meet_our_team_modal free_resources_form"
      id="exampleModal2"
      tabindex="-1"
      aria-labelledby="exampleModalLabel2"
      aria-hidden="true"
      >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="hideModal2"
            ></button>
          </div>
          <div class="modal-body">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                id="closed2"
                src="https://player.vimeo.com/video/1010754792?h=3027dd6c0d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                "
                title="Sitting Pretty"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
// import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  name: "self_assessments",
  created(){
     document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
  },
  methods:{
    hideModal() {
      document.getElementById("closed").src += "";
    },
    hideModal1() {
      document.getElementById("closed1").src += "";
    },
    hideModal2() {
      document.getElementById("closed2").src += "";
    },
  },
  mounted() {
   
    store.dispatch("Home/getHomeData").then(() => {});
  },
};
</script>